import AboutCiteCeo from './about_us/about-cite-ceo.png'
import EmptyQA from './course/empty-qa.svg'
import EmptyRate from './course/empty-rate.svg'

const Images = {
  AboutCiteCeo,
  EmptyQA,
  EmptyRate,
}

export default Images
