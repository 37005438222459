class Navbar {
  constructor() {
    this.sideMenu = document.getElementById('side-menu')
    this.revealMenuBtn = document.getElementById('reveal-menu-btn')
    this.dismissMenuBtn = document.getElementById('dismiss-menu-btn')
    this.revealCoursesCategoriesBtn = document.getElementById('reveal-courses-categories-btn')
    this.coursesCategoriesMenu = document.getElementById('courses-categories-menu')

    if (this.revealMenuBtn) {
      this.revealMenuBtn.addEventListener('click', () => this.revealMenu())
    }
    if (this.dismissMenuBtn) {
      this.dismissMenuBtn.addEventListener('click', () => this.dismissMenu())
    }
    if (this.revealCoursesCategoriesBtn) {
      this.revealCoursesCategoriesBtn.addEventListener('click', () => this.toggleCourseCategories())
    }
  }
  revealMenu() {
    this.sideMenu.classList.add('reveal')
  }
  dismissMenu() {
    this.sideMenu.classList.remove('reveal')
  }
  clearSearchInputs() {
    const searchInput = this.searchPanel.getElementsByClassName('search-input')[0]
    if (searchInput) {
      searchInput.value = ''
    }
  }
  toggleCourseCategories() {
    this.coursesCategoriesMenu.classList.toggle('collapsed')
  }
}

export default Navbar
