require.context('../images', true)

import Navbar from '../visiting/navbar'
import SearchPanel from '../visiting/search_panel'
import FloatingBottomPanel from '../visiting/floating_bottom_panel'

document.addEventListener('DOMContentLoaded', () => {
  new Navbar()
  new SearchPanel()
  new FloatingBottomPanel()
})
