import 'core-js/stable'
import 'regenerator-runtime/runtime'

const REACHING_WINDOW_TOP_OFFSET = 80

class FloatingBottomPanel {
  constructor() {
    this.previousVisible = null
    this.visible = false
    window.addEventListener('scroll', () => this.scrollingHandler())
  }

  toggleVisible() {
    const floatingPanel = document.getElementById('floating-panel')
    if (this.visible) {
      floatingPanel.classList.remove('invisible')
      floatingPanel.classList.add('visible')
    } else {
      floatingPanel.classList.remove('visible')
      floatingPanel.classList.add('invisible')
    }
  }

  scrollingHandler() {
    const elementRef = document.getElementById('floating-panel-observed-ref')
    if (!elementRef) return

    const observedRect = elementRef.getBoundingClientRect()
    this.previousVisible = this.visible
    this.visible = (observedRect.top < REACHING_WINDOW_TOP_OFFSET)
    if (this.visible !== this.previousVisible) {
      this.toggleVisible()
    }

    if (this.visible) {
      document.body.classList.add('floating-panel-active')
    } else {
      document.body.classList.remove('floating-panel-active')
    }
  }
}

export default FloatingBottomPanel
