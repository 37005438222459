class SearchPanel {
  constructor() {
    this.searchBtn = document.getElementById('search-btn')
    this.searchPanel = document.getElementById('search-panel')
    if (this.searchPanel) {
      this.searchPanelClearBtn = this.searchPanel.getElementsByClassName('clear-btn')[0]
    }

    if (this.searchBtn) {
      this.searchBtn.addEventListener('click', () => this.toggleSearchPanel())
    }
    if (this.searchPanelClearBtn) {
      this.searchPanelClearBtn.addEventListener('click', () => {
        this.dismissSearchPanel()
        this.clearSearchInputs()
      })
    }
  }
  revealSearchPanel() {
    this.searchPanel.classList.add('active')
  }
  dismissSearchPanel() {
    this.searchPanel.classList.remove('active')
  }
  toggleSearchPanel() {
    if (this.searchPanel.classList.contains('active')) {
      this.dismissSearchPanel()
    } else {
      this.revealSearchPanel()
    }
  }
  clearSearchInputs() {
    const searchInput = this.searchPanel.getElementsByClassName('search-input')[0]
    if (searchInput) {
      searchInput.value = ''
    }
  }
}

export default SearchPanel
